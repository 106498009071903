import React, { useState, useEffect, useRef } from 'react';
import { __, sprintf } from '@wordpress/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { is, isEmpty, isNil, sum, pathOr, head } from 'ramda';
import { klona } from 'klona';
import { wrap } from 'object-path-immutable';

// store
import { storeSet, useStore } from '../../store';

// api
import ApplicationEvaluationService from '../../service/application-evaluation-service';
import AmendmentsService from '../../service/amendments-service';

// tools
import set404FromErrorResponse from '../../helpers/set404FromErrorResponse';
import getBandoLabel from '../../helpers/getBandoLabel';
import getDateFromISOstring from '../../helpers/getDateFromISOstring';

// components
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Checkbox } from 'primereact/checkbox';
import { Editor } from 'primereact/editor';
import { InputNumber } from 'primereact/inputnumber';
import BlockingOverlay from '../../components/BlockingOverlay';
import { Toast } from 'primereact/toast';
import HelpIcon from '../../icons/HelpIcon';
import { Dialog } from 'primereact/dialog';
import ArchiveDocument from './components/ArchiveDocument';
import { classNames } from 'primereact/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import DownloadApplicationArchive from './components/DownloadApplicationArchive';
import DownloadCompanyDelegation from './components/DownloadCompanyDelegation';
import DownloadSignedApplication from './components/DownloadSignedApplication';
import AppointmentService from '../../service/appointment-service';
import ListOfFiles from './components/ListOfFiles';

const APP_EVALUATION_FLOW_ID = process.env.REACT_APP_EVALUATION_FLOW_ID;

const DomandaEditPreInstructor = () => {
    const isAsyncRequest = useStore().main.isAsyncRequest();
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [isVisibleCriterionData, setIsVisibleCriterionData] = useState(0);
    const [criterionDataTitle, setCriterionDataTitle] = useState('');
    const [criterionDataContent, setCriterionDataContent] = useState('');
    const [isAdmissible, setIsAdmissible] = useState(false);
    const [connectedSoccorsoId, setConnectedSoccorsoId] = useState(0);
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [isVisibleCompleteDialog, setIsVisibleCompleteDialog] = useState(false);
    const [operationType, setOperationType] = useState('');
    const [motivation, setMotivation] = useState('');
    const [isVisibleAppointmentDialog, setIsVisibleAppointmentDialog] = useState(false);
    const [allFilesRated, setAllFilesRated] = useState(false);
    const [atLeastOneChecked, setAtLeastOneChecked] = useState(false);
    const [allChecksChecked, setAllChecksChecked] = useState(false);
    const [appointmentData, setAppointmentData] = useState({
        title: '',
        text: '',
        duration: 0,
        amount: 0
    });

    const goToEvaluationsPage = () => {
        navigate('/domande');
    }

    const updateFlagsForSoccorso = (data) => {
        if (data.files) {
            const nonRatedFiles = data.files
                .map(el => el.valid)
                .filter(v => isNil(v));
            setAllFilesRated(nonRatedFiles.length === 0);
        }
        if (data.checklist) {
            const checkedChecklistItems = data.checklist
                .map(el => el.valid)
                .filter(v => v);
            setAtLeastOneChecked(checkedChecklistItems.length > 0);
            setAllChecksChecked(checkedChecklistItems.length === data.checklist.length)
        }
    }

    const doNewSoccorso = () => {
        if (connectedSoccorsoId !== 0) {
            doSaveDraft(`/domande/${id}/soccorso/${connectedSoccorsoId}`)
        } else {
            doSaveDraft(`/domande/${id}/aggiungi-soccorso/`)
        }
    }

    const getCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setData(getFormattedData(data.data));
            setMotivation(data.data.motivation);
            updateFlagsForSoccorso(data.data);
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const getFormattedData = (data) => {
        data.submissionDate = is(String, data.submissionDate) ? new Date(data.submissionDate) : (data.submissionDate ? data.submissionDate : '');
        data.evaluationDate = is(String, data.evaluationDate) ? new Date(data.evaluationDate) : (data.evaluationDate ? data.evaluationDate : '');
        return data;
    };

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <button className="ql-link" aria-label="Link"></button>
                <button className="ql-list" value="ordered"></button>
                <button className="ql-header" value="2"></button>
                <button className="ql-header" value="3"></button>
                <button className="ql-blockquote"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-indent" value="-1"></button>
                <button className="ql-indent" value="+1"></button>
            </span>
        );
    };

    const header = renderHeader();

    const updateEvaluationValue = (value, path, maxValue = null) => {
        let finalValue = value;

        if (maxValue || maxValue === 0) {
            finalValue = value > maxValue ? maxValue : value;
        }

        const newData = wrap(data).set(path, finalValue).value();
        setData(newData);
        updateFlagsForSoccorso(newData);
    }

    const doSaveDraft = (doRedirect = '') => {
        const formData = {
            criteria: klona(data.criteria),
            checklist: klona(data.checklist),
            files: klona(data.files),
            note: data.note
        }

        ApplicationEvaluationService.updateEvaluation(
            data.assignedApplicationId,
            formData,
            (data) => updateCallback(data, doRedirect),
            errUpdateCallback
        );
    }

    const updateCallback = (data, doRedirect = '') => {
        if (data.status === 'SUCCESS') {
            setData(getFormattedData(data.data));
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: data.message
                });
            }
            if (!isEmpty(doRedirect)) {
                navigate(doRedirect);
            }
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errUpdateCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const doApprove = () => {
        const formData = {
            applicationStatus: 'APPROVED',
            criteria: klona(data.criteria),
            checklist: klona(data.checklist),
            files: klona(data.files),
            note: data.note,
            motivation
        }

        ApplicationEvaluationService.updateEvaluation(data.assignedApplicationId, formData, updateStatusCallback, errUpdateStatusCallback);
    }

    const doReject = () => {
        const formData = {
            applicationStatus: 'REJECTED',
            criteria: klona(data.criteria),
            checklist: klona(data.checklist),
            files: klona(data.files),
            note: data.note,
            motivation
        }

        ApplicationEvaluationService.updateEvaluation(data.assignedApplicationId, formData, updateStatusCallback, errUpdateStatusCallback);
    }

    const updateStatusCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: data.message
                });
            }
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errUpdateStatusCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const displayCriterionData = (id) => {
        const criterion = head(data.criteria.filter(o => o.id === id));
        setCriterionDataTitle(criterion.label);
        const content = <div className="criterionRelatedData">
            <h3>{__('I campi correlati')}</h3>
            {criterion.criteriaMappedFields ? criterion.criteriaMappedFields.map(o => criteriaDataItem(o)) : null}
        </div>;
        setCriterionDataContent(content);
        setIsVisibleCriterionData(id);
    }

    const criteriaDataItem = (item) => {
        let content = '';

        switch (item.fieldName) {
            case 'fileupload' :
                content = <ul>
                    {item.fieldValue.map(o => <li key={o.id}>
                        {o.filePath ? <a href={o.filePath}>{o.name}</a> : null}
                    </li>)}
                </ul>;
                break;
            case 'table' :
                const th = Object.keys(item.fieldValue[0]);
                content = <table>
                    <thead>
                    <tr>
                        {th.map(v => <th key={v}>{v}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {item.fieldValue.map((o, i) => <tr key={i}>
                        {Object.values(o).map(v => <td key={v}>{v}</td>)}
                    </tr>)}
                    </tbody>
                </table>;
                break;
            default :
                content = item.fieldValue;
                break;
        }

        return <div key={item.id} className="criterionRelatedData__item">
            <strong>{item.fieldLabel}</strong>
            {content}
        </div>
    }

    const hideCriterionData = () => {
        setIsVisibleCriterionData(0);
        setCriterionDataTitle('');
        setCriterionDataContent('');
    }

    const getAmendmentsCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (data.data.length) {
                setConnectedSoccorsoId(data.data[0].id);
            }
        }
    }

    const errGetAmendmentsCallback = () => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
    }

    const shouldDisableField = (fieldName) => {
        return !['EVALUATION'].includes(data.applicationStatus)
            || (['ADMISSIBLE'].includes(data.applicationStatus) && fieldName !== 'criteria')
    }

    const headerCompleteDialog = () => {
        return 'approve' === operationType
            ? <span>{__('Confermare l\'approvazione', 'gepafin')}</span>
            : <span>{__('Confermare il rifiuto', 'gepafin')}</span>;
    }

    const hideCompleteDialog = () => {
        setIsVisibleCompleteDialog(false);
        setOperationType('');
        setMotivation('');
    }

    const footerCompleteDialog = () => {
        return <div>
            <Button type="button" label={__('Anulla', 'gepafin')} onClick={hideCompleteDialog} outlined/>
            <Button
                type="button"
                disabled={loading}
                label={__('Invia', 'gepafin')} onClick={'approve' === operationType ? doApprove : doReject}/>
        </div>
    }

    const initiateApproving = () => {
        setOperationType('approve');
        setIsVisibleCompleteDialog(true);

    }

    const initiateRejecting = () => {
        setOperationType('reject');
        setIsVisibleCompleteDialog(true);
    }

    const doCheckNDG = () => {
        storeSet.main.setAsyncRequest();
        doSaveDraft();
        setTimeout(() => {
            AppointmentService.getNdg(id, getNdgCallback, errGetNdgCallback);
        }, 100);
    }

    const getNdgCallback = (data) => {
        if (data.status === 'SUCCESS') {
            console.log('data', data.data);
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetNdgCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const doCreateAppointment = () => {
        setAppointmentData({
            title: '',
            text: '',
            duration: 0,
            amount: 0
        });
        setIsVisibleAppointmentDialog(true);
    }

    const setValue = (name, value) => {
        const newData = wrap(appointmentData).set(name, value).value();
        setAppointmentData(newData);
    }

    const headerAppointmentDialog = () => {
        return <span>{__('Crea appuntamento', 'gepafin')}</span>;
    }

    const hideAppointmentDialog = () => {
        setIsVisibleAppointmentDialog(false);
        setAppointmentData({});
    }

    const footerAppointmentDialog = () => {
        return <div>
            <Button type="button" label={__('Anulla', 'gepafin')} onClick={hideAppointmentDialog} outlined/>
            <Button
                type="button"
                disabled={loading}
                label={__('Invia', 'gepafin')} onClick={doCreateAppointmentRequest}/>
        </div>
    }

    const doCreateAppointmentRequest = () => {
        if (
            !isEmpty(appointmentData.title) && !isEmpty(appointmentData.text) && !isEmpty(appointmentData.amount)
        && !isEmpty(appointmentData.duration) && appointmentData.duration !== 0 && appointmentData.amount !== 0
        ) {
            console.log(appointmentData);
        }
    }

    const doMakeAdmisible = () => {
        // TODO
    }

    useEffect(() => {
        const maxScore = pathOr(0, ['minScore'], data);
        const criteria = pathOr([], ['criteria'], data);
        const scoreSum = sum(criteria.map(o => o.score));

        setIsAdmissible(scoreSum !== 0 && scoreSum >= maxScore);
    }, [data]);

    useEffect(() => {
        const parsed = parseInt(id)
        const entityId = !isNaN(parsed) ? parsed : 0;

        storeSet.main.setAsyncRequest();
        ApplicationEvaluationService.getEvaluationByApplId(getCallback, errGetCallback, [
            ['applicationId', entityId]
        ]);
        AmendmentsService.getSoccorsoByApplId(entityId, getAmendmentsCallback, errGetAmendmentsCallback, [
            ['statuses', 'AWAITING']
        ]);
    }, [id]);

    return (
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Valuta domanda', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>
            <Toast ref={toast}/>
            <BlockingOverlay shouldDisplay={isAsyncRequest}/>

            <div className="appPageSection__row">
                <Button
                    type="button"
                    outlined
                    onClick={goToEvaluationsPage}
                    label={__('Indietro', 'gepafin')}
                    icon="pi pi-arrow-left" iconPos="left"/>
            </div>

            <div className="appPage__spacer"></div>

            {!isAsyncRequest && !isEmpty(data)
                ? <div className="appPage__content">
                    <div className="appPageSection__withBorder columns">
                        <p className="appPageSection__pMeta">
                            <span>{__('ID domanda', 'gepafin')}</span>
                            <span>{data.applicationId}</span>
                        </p>
                        <p className="appPageSection__pMeta">
                            <span>{__('Protocollo', 'gepafin')}</span>
                            <span>{data.protocolNumber}</span>
                        </p>
                        <p className="appPageSection__pMeta">
                            <span>{__('NDG', 'gepafin')}</span>
                            <span>{data.ndg}</span>
                        </p>
                        <p className="appPageSection__pMeta">
                            <span>{__('Appuntamento', 'gepafin')}</span>
                            <span>{data.appointmentId}</span>
                        </p>
                        <p className="appPageSection__pMeta">
                            <span>{__('Bando', 'gepafin')}</span>
                            <span>{data.callName}</span>
                        </p>
                        <p className="appPageSection__pMeta">
                            <span>{__('Beneficiario', 'gepafin')}</span>
                            <span>{data.beneficiary}</span>
                        </p>
                        <p className="appPageSection__pMeta">
                            <span>{__('Azienda', 'gepafin')}</span>
                            <span>{data.companyName}</span>
                        </p>
                        <p className="appPageSection__pMeta">
                            <span>{__('Data ricezione', 'gepafin')}</span>
                            <span>{getDateFromISOstring(data.submissionDate)}</span>
                        </p>
                        <p className="appPageSection__pMeta">
                            <span>{__('Data assegnazione', 'gepafin')}</span>
                            <span>{getDateFromISOstring(data.assignedAt)}</span>
                        </p>
                        <p className="appPageSection__pMeta">
                            <span>{__('Scadenza Valutazione', 'gepafin')}</span>
                            <span>{getDateFromISOstring(data.evaluationEndDate)}</span>
                        </p>
                        <p className="appPageSection__pMeta">
                            <span>{__('Stato', 'gepafin')}</span>
                            <span>{getBandoLabel(data.applicationStatus)}</span>
                        </p>
                    </div>

                    <div className="appPageSection">
                        <h2>{__('Scarica documenti della domanda', 'gepafin')}</h2>
                        <div className="appPageSection__row autoFlow">
                        <DownloadApplicationArchive applicationId={id}/>
                            <DownloadSignedApplication applicationId={id}/>
                            <DownloadCompanyDelegation applicationId={id}/>
                        </div>
                    </div>

                    <div className="appPageSection">
                        <h2>{__('Checklist Valutazione', 'gepafin')}</h2>
                        <div className="appPageSection columns">
                            <div>

                                <h3>{__('Lista', 'gepafin')}</h3>
                                <div className="appPageSection__withBorder grey" style={{ marginBottom: '20px' }}>
                                    <div className="appPageSection__checklist">
                                        {data.checklist.map((o, i) => <div key={o.id}>
                                            <Checkbox
                                                disabled={shouldDisableField('checklist')}
                                                inputId={`checklist_${o.id}`}
                                                onChange={(e) => updateEvaluationValue(
                                                    e.checked,
                                                    ['checklist', i, 'valid']
                                                )}
                                                checked={o.valid}></Checkbox>
                                            <label htmlFor={`checklist_${o.id}`}>{o.label}</label>
                                        </div>)}
                                    </div>
                                </div>

                                <h3>{__('Note', 'gepafin')}</h3>
                                <div>
                                    <Editor
                                        value={data.note}
                                        readOnly={shouldDisableField('note')}
                                        placeholder={__('Digita qui il messagio', 'gepafin')}
                                        headerTemplate={header}
                                        onTextChange={(e) => updateEvaluationValue(
                                            e.htmlValue,
                                            ['note']
                                        )}
                                        style={{ height: 80 * 3, width: '100%' }}
                                    />
                                </div>
                            </div>
                            <div>
                                <h3>{__('Documenti allegati', 'gepafin')}</h3>
                                <ListOfFiles
                                    files={data.files}
                                    updateFn={updateEvaluationValue}
                                    shouldDisableFieldFn={shouldDisableField}
                                    name="files"
                                    ndg={data.ndg}
                                    applicationId={id}/>
                            </div>
                        </div>
                    </div>

                    <div className="appPageSection">
                        <h2>{__('Punteggi di valutazione', 'gepafin')}</h2>
                        {data.criteria
                            ? <table className="myTable">
                                <thead className="myThead">
                                <tr>
                                    <th>{__('Parametro', 'gepafin')}</th>
                                    <th style={{ width: 200 }}>{__('Punteggio', 'gepafin')}</th>
                                    <th style={{ width: 220 }}>{__('Stato', 'gepafin')}</th>
                                </tr>
                                </thead>
                                <tbody className="myTbody">
                                {data.criteria.map((o, i) => <tr key={o.id}>
                                    <td>{o.label}</td>
                                    <td>
                                        <div className="p-inputgroup">
                                            <InputNumber
                                                disabled={shouldDisableField('criteria')}
                                                placeholder={__('Punteggio', 'gepafin')}
                                                keyfilter="int"
                                                value={o.score}
                                                max={o.maxScore}
                                                onChange={(e) => updateEvaluationValue(
                                                    e.value,
                                                    ['criteria', i, 'score'],
                                                    o.criteria
                                                )}/>
                                            <span className="p-inputgroup-addon">
                                                / {o.maxScore}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="appPageSection__iconActions">
                                            {!isEmpty(o.criteriaMappedFields)
                                                ? <Button icon="pi pi-eye"
                                                          rounded outlined severity="info"
                                                          onClick={() => displayCriterionData(o.id)}
                                                          aria-label={__('Mostra', 'gepafin')}/> : null}
                                            <Button icon="pi pi-thumbs-up" rounded outlined
                                                    disabled={shouldDisableField('criteria')}
                                                    severity={!isNil(o.valid) && o.valid ? 'success' : 'secondary'}
                                                    onClick={() => updateEvaluationValue(
                                                        true,
                                                        ['criteria', i, 'valid']
                                                    )}
                                                    aria-label={__('Su', 'gepafin')}/>
                                            <Button icon="pi pi-thumbs-down" rounded outlined
                                                    disabled={shouldDisableField('criteria')}
                                                    severity={!isNil(o.valid) && !o.valid ? 'danger' : 'secondary'}
                                                    onClick={() => updateEvaluationValue(
                                                        false,
                                                        ['criteria', i, 'valid']
                                                    )}
                                                    aria-label={__('Giu', 'gepafin')}/>
                                        </div>
                                    </td>
                                </tr>)}
                                <tr>
                                    <td>{__('Punteggio:', 'gepafin')}</td>
                                    <td>{sum(data.criteria.map(o => o.score))}</td>
                                    <td>
                                        {isAdmissible
                                            ? <Tag icon="pi pi-check" severity="success"
                                                   value={__('Ammissibile')}></Tag> : null}
                                        {!isAdmissible
                                            ? <Tag icon="pi pi-times" severity="warning"
                                                   value={__('Inammissibile')}></Tag> : null}
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot className="myTfoot">
                                <tr>
                                    <td colSpan="3">{sprintf(__('Punteggio minimo per l\'ammissione: %d'), data.minScore)}</td>
                                </tr>
                                </tfoot>
                            </table> : null}
                    </div>

                    <div className="appPage__spacer"></div>

                    <div className="appPageSection__hr">
                        <span>{__('Azioni rapide', 'gepafin')}</span>
                    </div>

                    <div className="appPageSection">
                        <div className="appPageSection__actions">
                            {['EVALUATION', 'SOCCORSO', 'CLOSE'].includes(data.applicationStatus)
                                ? <Button
                                    type="button"
                                    disabled={!data.id || data.status === 'CLOSE' || !allFilesRated || !atLeastOneChecked}
                                    onClick={doNewSoccorso}
                                    outlined
                                    label={<>
                                        {data.applicationStatus === 'EVALUATION'
                                            ? __('Richiedi Soccorso Istruttorio', 'gepafin')
                                            : __('Apri Soccorso Istruttorio', 'gepafin')}
                                        <i style={{ marginLeft: 7 }}>
                                            <HelpIcon/>
                                        </i>
                                    </>}
                                /> : null}
                            {data.id
                                ? <Button
                                    type="button"
                                    disabled={data.status === 'CLOSE'}
                                    onClick={() => doSaveDraft()}
                                    outlined
                                    label={__('Salva bozza valutazione', 'gepafin')}
                                    icon="pi pi-save" iconPos="right"/>
                                : <Button
                                    type="button"
                                    onClick={() => doSaveDraft()}
                                    label={__('Crea valutazione', 'gepafin')}
                                    icon="pi pi-save" iconPos="right"/>}
                            {APP_EVALUATION_FLOW_ID === '1' && ['EVALUATION'].includes(data.applicationStatus)
                                ? <Button
                                    type="button"
                                    disabled={!data.id || !allFilesRated || !allChecksChecked}
                                    onClick={doCheckNDG}
                                    label={__('Controlla NDG', 'gepafin')}
                                /> : null}
                            {APP_EVALUATION_FLOW_ID === '1' && ['EVALUATION'].includes(data.applicationStatus) && data.ndg
                                ? <Button
                                    type="button"
                                    disabled={!data.id}
                                    onClick={doCreateAppointment}
                                    label={__('Crea l\'appuntamento', 'gepafin')}
                                /> : null}
                            {APP_EVALUATION_FLOW_ID === '1' && ['APPOINTMENT'].includes(data.applicationStatus)
                                ? <Button
                                    type="button"
                                    disabled={!data.id}
                                    onClick={doMakeAdmisible}
                                    label={__('Ammissibile', 'gepafin')}
                                /> : null}
                            {data.id
                                ? <Button
                                    type="button"
                                    disabled={!isAdmissible
                                        || (APP_EVALUATION_FLOW_ID === '1' && !['ADMISSIBLE', 'APPOINTMENT'].includes(data.applicationStatus))}
                                    onClick={initiateApproving}
                                    label={__('Approva Domanda', 'gepafin')}
                                    icon="pi pi-check" iconPos="right"/> : null}
                            {data.id
                                ? <Button
                                    type="button"
                                    disabled={APP_EVALUATION_FLOW_ID === '1' && !['ADMISSIBLE', 'APPOINTMENT'].includes(data.applicationStatus)}
                                    onClick={initiateRejecting}
                                    label={__('Respingi Domanda', 'gepafin')}
                                    icon="pi pi-times" iconPos="right"/> : null}
                        </div>
                    </div>

                    <Dialog
                        header={criterionDataTitle}
                        visible={isVisibleCriterionData !== 0}
                        style={{ width: '50vw' }}
                        onHide={hideCriterionData}>
                        {criterionDataContent}
                    </Dialog>

                    <Dialog
                        visible={isVisibleCompleteDialog}
                        modal
                        header={headerCompleteDialog}
                        footer={footerCompleteDialog}
                        style={{ maxWidth: '600px', width: '100%' }}
                        onHide={hideCompleteDialog}>
                        <div className="appForm__field">
                            <label>{__('Motivazione', 'gepafin')}</label>
                            <Editor
                                value={motivation}
                                readOnly={loading}
                                placeholder={__('Digita qui il messagio', 'gepafin')}
                                headerTemplate={header}
                                onTextChange={(e) => setMotivation(e.htmlValue)}
                                style={{ height: 80 * 3, width: '100%' }}
                            />
                        </div>
                    </Dialog>

                    <Dialog
                        visible={isVisibleAppointmentDialog}
                        modal
                        header={headerAppointmentDialog}
                        footer={footerAppointmentDialog}
                        style={{ maxWidth: '600px', width: '100%' }}
                        onHide={hideAppointmentDialog}>
                        <div className="appForm__field">
                            <label
                                className={classNames({ 'p-error': isEmpty(appointmentData.amount) || appointmentData.amount === 0 })}>
                                {__('Importo', 'gepafin')}
                            </label>
                            <InputNumber
                                value={appointmentData.amount}
                                keyfilter="int"
                                invalid={isEmpty(appointmentData.amount) || appointmentData.amount === 0}
                                onChange={(e) => setValue('amount', e.value)}/>
                        </div>
                        <div className="appForm__field">
                            <label
                                className={classNames({ 'p-error': isEmpty(appointmentData.duration) || appointmentData.duration === 0 })}>
                                {__('Durata', 'gepafin')}
                            </label>
                            <InputNumber
                                value={appointmentData.duration}
                                keyfilter="int"
                                invalid={isEmpty(appointmentData.duration) || appointmentData.duration === 0}
                                onChange={(e) => setValue('duration', e.value)}/>
                        </div>
                        <div className="appForm__field">
                            <label className={classNames({ 'p-error': isEmpty(appointmentData.title) })}>
                                {__('Titolo', 'gepafin')}
                            </label>
                            <InputText
                                value={appointmentData.title}
                                invalid={isEmpty(appointmentData.title)}
                                onChange={(e) => setValue('title', e.target.value)}/>
                        </div>
                        <div className="appForm__field">
                            <label className={classNames({ 'p-error': isEmpty(appointmentData.text) })}>
                                {__('Messaggio', 'gepafin')}
                            </label>
                            <InputTextarea
                                value={appointmentData.text}
                                invalid={isEmpty(appointmentData.text)}
                                onChange={(e) => setValue('text', e.target.value)}
                                rows={3}
                                cols={30}/>
                        </div>
                    </Dialog>

                </div>
                : <>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="4rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="4rem"></Skeleton>
                </>}
        </div>
    )

}

export default DomandaEditPreInstructor;